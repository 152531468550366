<template>
  <base-section id="theme-features">
    <base-section-heading title="MavenX Features"> </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            customText="Features"
          >
            -----
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "DealflowMoreFeatures",

  data: () => ({
    features: [
      {
        title: "Workflow templates",
        image: "workflow-templates",
        text: "MavenX provides pre-configured workflow templates for common deal processes, such as due diligence and post-close integration and these templates are customizable.",
      },
      {
        title: "Integration with Data Providers",
        image: "integration-with-data-providers",
        text: "Seamlessly integrate with leading financial data providers like Bloomberg, Reuters, HIS Markit, etc.",
      },
      {
        title: "PowerBI and Microsoft plugins",
        image: "powerbi",
        text: "MavenX provides integration with PowerBI for data visualization and Microsoft Outlook and Excel plugins for seamless data integration.",
      },
      {
        title: "Modular Screen Utilities ",
        image: "modular-screen",
        text: "MavenX offers customizable screens for specific use cases, a drag-and-drop screen builder, and integration with existing workflows",
      },
      {
        title: "Custom fields",
        image: "custom-fields",
        text: "MavenX allows users to create custom fields, which can be tailored to fit specific business needs. This helps ensure that data is entered consistently and in a standardized format.",
      },
      {
        title: "Alert notifications",
        image: "alert-notifications",
        text: "These alerts help ensure that everyone is aware of important updates and can take action as needed.",
      },
      {
        title: "Manage Restricted lists",
        image: "manage-restricted-lists",
        text: "MavenX offers tools to manage restricted lists, automated compliance checks, and integration with external compliance databases.",
      },
      {
        title: "IP restrictions",
        image: "ip-restrictions",
        text: "MavenX allows users to restrict access to the platform based on IP addresses. This helps prevent unauthorized access from unknown or untrusted devices.",
      },
      {
        title: "Data validation",
        image: "data-validation",
        text: "MavenX provides data validation rules to ensure that data entered into the platform meets specific criteria. For example, data validation rules can be set up to ensure that email addresses are formatted correctly, or that dates are entered in a standardized format",
      },
      {
        title: "Integrated reference data",
        image: "integrated-reference-data",
        text: "MavenX can seamlessly integrate with your centralized data repository, leveraging standardized reference data, and data mapping.",
      },
      {
        title: "User activity tracking",
        image: "user-activity-tracking",
        text: "MavenX tracks user activity within the platform, including logins, document uploads, and modifications. This helps administrators identify any unauthorized access or suspicious activity.",
      },
      {
        title: "Debt management",
        image: "debt-management",
        text: "MavenX provides debt management tools, including debt investment and loan management, tracking of covenants and other loan terms, and amortization and interest calculations.",
      },
    ],
  }),
};
</script>
